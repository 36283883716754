export const COLOR = {
  MAIN_WHITE: "#ffffff",
  MAIN_BLACK: "#050E04",
  MAIN_BACKGROUND: "#F4F4FC",
  MAIN_BLUE: "#2196F3",
  MAIN_RED: "#E5341C",
  MAIN_GREEN: "#45AA4F",
  MAIN_NAVY: "#0C538B",
  MAIN_HOVER: "#E3EEFA",
  MAIN_BORDER: "#C4C4CD",
  BORDER_GRAY: "#9696A3",
  MAIN_PURPLE: "#3E1B6C",
  FONT_GRAY: "#9A9A9A",
  MAIN_SKYBLUE: "#BAD7E9",
  MAIN_ROSE: "#EB455F",
  MAIN_ORANGE: "#FB8C00",
};
